import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

const CustomCard = ({ classes = "", children }) => {
  return (
    <>
      <Card
        elevation={5}
        className={`sectionStyle ${classes}`}
        style={{
          backgroundColor: "#fff",
          boxShadow: "0px 0px 8px 5px rgba(0,0,0,0.3)",
        }}
      >
        {children}
      </Card>
    </>
  );
};

CustomCard.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export { CustomCard };
