import React from "react";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import ImageCard from "../components/ImageCard";
import Brodie from "../files/Brodie.jpg";
import CSS from "../files/languageLogos/css.jpg";
import Figma from "../files/languageLogos/figma.svg";
import Git from "../files/languageLogos/git.jpg";
import HTMLImage from "../files/languageLogos/html.jpg";
import Illustrator from "../files/languageLogos/illustrator.jpg";
import JS from "../files/languageLogos/javascript.jpg";
import NodeJS from "../files/languageLogos/nodejs.jpg";
import Photoshop from "../files/languageLogos/photoshop.jpg";
import Python from "../files/languageLogos/python.jpg";
import ReactImage from "../files/languageLogos/react.jpg";
import Wordpress from "../files/languageLogos/wordpress.jpg";
import Java from "../files/languageLogos/java.jpg";
import { Layout } from "../components/Layout";
import Button from "@mui/material/Button";

function Home() {
  const title = (
    <div className="sectionStyle" style={{ textAlign: "center" }}>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ margin: "auto" }}
      >
        <Grid
          item
          container
          xs={8}
          display="flex"
          justifyContent="center"
          style={{ margin: "auto" }}
        >
          <p className="titleText">Hi, I&apos;m Brodie Gullic!</p>
          <br />
          <p style={{ maxWidth: "90%" }}>
            I&apos;m an Indigenous software engineer who is passionate about
            advocating for Indigenous people and communities
          </p>
          <br />
        </Grid>
        <Grid
          item
          container
          xs={4}
          display="flex"
          justifyContent="center"
          style={{ margin: "auto" }}
        >
          <img
            src={Brodie}
            alt="Picture of me"
            style={{
              width: "33vw",
              height: "33vw",
              verticalAlign: "middle",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  const thisPage = (
    <div className="sectionStyle" style={{ textAlign: "center" }}>
      <p className="titleText">How does this site work?</p>
      <br />
      <p>
        This website is made with{" "}
        <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
          Gatsby
        </a>
        , a React Framework. Gatsby compiles Javascript code into HTML that can
        be quickly loaded by users and search engines alike. This site utilizes{" "}
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          React
        </a>
        &nbsp;to build the front-end along with Google&apos;s&nbsp;
        <a href="https://material-ui.com/" target="_blank" rel="noreferrer">
          Material-UI Library
        </a>{" "}
        to make a cleaner and easier to design React project. The site is hosted
        on&nbsp;
        <a href="https://reclaimhosting.com/" target="_blank" rel="noreferrer">
          Reclaim Hosting
        </a>
        .
      </p>
    </div>
  );

  const programmingCard = ImageCard(
    [
      ["HTML", HTMLImage, 1],
      ["CSS", CSS, 1.41],
      ["JAVASCRIPT", JS, 1.15],
      ["REACT", ReactImage, 1],
      ["NODE.JS", NodeJS, 0.61],
      ["PYTHON", Python, 1],
      ["JAVA", Java, 0.75],
      ["GIT", Git, 1],
    ],
    "PROGRAMMING SKILLS"
  );

  const otherCard = ImageCard(
    [
      ["WORDPRESS", Wordpress, 1],
      ["PHOTOSHOP", Photoshop, 1],
      ["ILLUSTRATOR", Illustrator, 1],
      ["FIGMA", Figma, 1],
    ],
    "OTHER SKILLS"
  );

  return (
    <>
      <Helmet>
        <title>Brodie Gullic - Software Engineer</title>
        <meta
          name="description"
          content="Hi, I'm Brodie Gullic! I'm an Indigenous software engineer who is passionate about advocating for Indigenous people and communities"
        />

        <meta property="og:title" content="Brodie Gullic" />
        <meta property="og:url" content="https://brodiegullic.com" />
        <meta
          property="og:description"
          content="Hi, I'm Brodie Gullic! I'm an Indigenous software engineer who is passionate about advocating for Indigenous people and communities"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="../files/OpenGraph.png" />

        <link rel="canonical" href="https://www.brodiegullic.com/" />
      </Helmet>
      <a href="#title"></a>
      <div style={{ marginBottom: "20vh" }}></div>

      {title}

      <hr className="separator" id="explanation" />

      {thisPage}

      <hr className="separator" id="programLang" />

      {programmingCard}

      <div style={{ marginBottom: "10vh" }} id="otherSkills"></div>

      {otherCard}

      <hr className="separator" id="experience" />
      <div style={{ textAlign: "center" }}>
        <p className="titleText" style={{ marginBottom: "5vh" }}>
          Curious about my experience?
        </p>
        <Button
          variant="contained"
          size="large"
          href="/experience"
          sx={{ fontSize: "max(2.5vw, 25px)" }}
        >
          EXPERIENCE
        </Button>
      </div>

      {/*<hr className="separator" id="contact" />
      <div style={{textAlign: "center"}}>
        <p className="titleText" style={{marginBottom: '5vh'}}>Looking to contact me?</p>
        <Button variant="contained" size="large" href="/contact" sx={{fontSize: 'max(2.5vw, 25px)'}}>
          CONTACT
        </Button>
      </div>*/}
    </>
  );
}

Home.Layout = Layout;

export default Home;
