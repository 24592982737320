import React from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CustomCard } from "./CustomCard";

/*
    array should look like [["text", image-url],["text", image-url]]
*/

function ImageCard(array, title, heightRatio) {
  const listItems = array.map((language) => (
    <div
      key={language[0]}
      style={{
        flexBasis: "25%",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px 0 20px 0",
      }}
    >
      <p>{language[0]}</p>
      <img
        src={language[1]}
        alt={language[0]}
        style={{
          height: "max(8vw, 100px)",
          verticalAlign: "middle",
        }}
      />
    </div>
  ));

  return (
    <>
      <CustomCard>
        <CardHeader title={title} disableTypography className="imageCardText" />
        <CardContent>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {listItems}
          </div>
        </CardContent>
      </CustomCard>
    </>
  );
}

export default ImageCard;
